import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import PieChartTemplate from "./PieChartTemplate";
import { financeDashboardData } from "../../helper/API/paymentTaskByAdvocate";
ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 22,
    marginBottom: "2rem",
    "& .MuiPaper-root": {
      padding: "1rem",
    },

    "& .MuiPaper-elevation": {
      boxShadow: "unset !important",
      borderRadius: 22,
    },
  },
  card: {
    marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: 11,
  },
});

const ProgressTemplate = ({ chartData }) => {
  const classes = useStyles();

  // useEffect(() => {
  //   const submitData = { transaction: "all" };
  //   financeDashboardData(submitData).then(res => {
  //     console.log("res get chart", res);
  //     if (res.success) {
  //       setChartData(res.data);
  //     }
  //   });
  // }, []);
  // console.log("chartData progress", chartData);
  const convertBrazilNumber = x => {
    return Number(x)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "brl",
    });
  };

  const Entradasfixas = chartData?.financialGoal - chartData?.ENTRADAS_FIX?.count ? chartData?.ENTRADAS_FIX?.count : 0;
  const Entradasesporádicas = chartData?.financialGoal - chartData?.ENTRADAS_ESPOR?.count ? chartData?.ENTRADAS_ESPOR?.count : 0;
  const Despesasfixas = chartData?.financialGoal - chartData?.DESPESAS_FIX?.count ? chartData?.DESPESAS_FIX?.count : 0;
  const EntradasEspor = chartData?.financialGoal - chartData?.DESPESAS_ESPOR?.count ? chartData?.DESPESAS_ESPOR?.count : 0;
  const lucro = chartData?.financialGoal - chartData?.LUCRO?.count ? chartData?.LUCRO?.count : 0;
  // console.log("Despesasfixas", Despesasfixas);
  // console.log("EntradasEspor", EntradasEspor);
  // console.log("Entradasfixas", Entradasfixas);
  // console.log("Entradasesporádicas", Entradasesporádicas);
  // console.log("EntradasEspor", EntradasEspor);
  // console.log("lucro", lucro);
  //image chart one two
  const image = new Image();
  image.src = "/assets/images/fixed_entry.png";
  image.id = "finance_img";

  const plugin = {
    id: "custom_canvas_background_image",
    beforeDraw: chart => {
      if (image.complete) {
        const ctx = chart.ctx;
        const { top, left, width, height } = chart.chartArea;
        const x = left + width / 2 - image.width / 2;
        const y = top + height / 1 - image.height / 1;
        ctx.drawImage(image, x + 40, y + 10, 50, 50);
      } else {
        image.onload = () => chart.draw();
      }
    },
  };
  //image chart three four
  const image2 = new Image();
  image2.src = "/assets/images/fixed_expence.png";
  image2.id = "finance_img";

  const plugin2 = {
    id: "custom_canvas_background_image_2",
    beforeDraw: chart => {
      if (image2.complete) {
        const ctx = chart.ctx;
        const { top, left, width, height } = chart.chartArea;
        const x = left + width / 2 - image2.width / 2;
        const y = top + height / 1 - image2.height / 1;
        ctx.drawImage(image2, x + 52, y + 32, 50, 50);
      } else {
        image2.onload = () => chart.draw();
      }
    },
  };
  //image chart five
  const image3 = new Image();
  image3.src = "/assets/images/profit.png";
  image3.id = "finance_img";

  const plugin3 = {
    id: "custom_canvas_background_image_2",
    beforeDraw: chart => {
      if (image3.complete) {
        const ctx = chart.ctx;
        const { top, left, width, height } = chart.chartArea;
        const x = left + width / 2 - image3.width / 2;
        const y = top + height / 1 - image3.height / 1;
        ctx.drawImage(image3, x + 36, y + 16, 50, 50);
      } else {
        image3.onload = () => chart.draw();
      }
    },
  };
  // var options = {
  //   cutout: "80%",
  //   tooltips: {
  //     callbacks: {
  //       title: function (tooltipItem, data) {
  //         console.log("tooltipItem", tooltipItem);
  //         console.log("data", data);
  //         return data["labels"][tooltipItem[0]["index"]];
  //       },
  //       label: function (tooltipItem, data) {
  //         return data["datasets"][0]["data"][tooltipItem["index"]];
  //       },
  //     },
  //   },
  // };

  var options = {
    cutout: "80%",
    // tooltips: function (tooltipItem, data) {
    //   return data["labels"][tooltipItem[0]["index"]];
    // },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItems => {
            // console.log("tooltipItems", tooltipItems);
            //convert tooltip number to brazilian number
            return convertBrazilNumber(tooltipItems.parsed);
          },
        },
      },
    },
  };

  const data1 = {
    datasets: [
      {
        data: [
          chartData?.ENTRADAS_FIX?.count ? chartData?.ENTRADAS_FIX?.count : 0,
          chartData?.financialGoal ? chartData?.financialGoal : 1,
        ],
        backgroundColor: ["#FFC107", "#FFC10710"],
      },
    ],
  };
  const data2 = {
    datasets: [
      {
        data: [
          chartData?.ENTRADAS_ESPOR?.count ? chartData?.ENTRADAS_ESPOR?.count : 0,
          chartData?.financialGoal ? chartData?.financialGoal : 1,
        ],
        backgroundColor: ["#FFC107", "#FFC10710"],
      },
    ],
  };
  const data3 = {
    datasets: [
      {
        data: [Despesasfixas ? Despesasfixas : 0, 1],
        backgroundColor: ["#5031A9", "#5031A910"],
      },
    ],
  };
  const data4 = {
    datasets: [
      {
        data: [EntradasEspor ? EntradasEspor : 0, 1],
        backgroundColor: ["#5031A9", "#5031A910"],
      },
    ],
  };
  const data5 = {
    datasets: [
      {
        data: [chartData?.LUCRO?.count ? chartData?.LUCRO?.count : 0, chartData?.financialGoal ? chartData?.financialGoal : 1],
        backgroundColor: [lucro > 0 ? "#71D875" : "red", "#71D87510"],
      },
    ],
  };

  const converBrazilNumber = x => {
    return Number(x)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "brl",
    });
  };

  // console.log("chartData", chartData);

  return (
    <div className={classes.root}>
      <Card variant="elevation" className={classes.card}>
        <CardContent style={{ display: "flex", justifyContent: "space-between" }}>
          <PieChartTemplate
            data={data1}
            options={options}
            plugins={plugin}
            subtitle={"Entradas fixas"}
            amount={`${chartData?.ENTRADAS_FIX?.count ? converBrazilNumber(chartData?.ENTRADAS_FIX?.count) : converBrazilNumber(0)}`}
            amountColor={"orange"}
          />
          <PieChartTemplate
            data={data2}
            options={options}
            plugins={plugin}
            subtitle={"Entradas esporádicas"}
            amount={`${chartData?.ENTRADAS_ESPOR?.count ? converBrazilNumber(chartData?.ENTRADAS_ESPOR?.count) : converBrazilNumber(0)}`}
            amountColor={"orange"}
          />
          <PieChartTemplate
            data={data3}
            options={options}
            plugins={plugin2}
            subtitle={"Despesas fixas"}
            amount={`${chartData?.DESPESAS_FIX?.count ? converBrazilNumber(chartData?.DESPESAS_FIX?.count) : converBrazilNumber(0)}`}
            amountColor={"purple"}
          />
          <PieChartTemplate
            data={data4}
            options={options}
            plugins={plugin2}
            subtitle={"Despesas esporádicas"}
            amount={`${chartData?.DESPESAS_ESPOR?.count ? converBrazilNumber(chartData?.DESPESAS_ESPOR?.count) : converBrazilNumber(0)}`}
            amountColor={"purple"}
          />
          <PieChartTemplate
            data={data5}
            options={options}
            plugins={plugin3}
            subtitle={lucro > 0 ? "Lucro" : "Perda"}
            amount={`${chartData?.LUCRO?.count ? converBrazilNumber(chartData?.LUCRO?.count) : converBrazilNumber(0)}`}
            amountColor={lucro > 0 ? "green" : "red"}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default ProgressTemplate;
