import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, CardContent, Card, Button, TextField } from "@mui/material";
import CustomProgressBar from "../Inputs/ProgressBar";
import styled from "@emotion/styled";
import { getPaymentTaskGoals, paymentTaskGoals } from "../../helper/API/paymentTaskByAdvocate";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { withStyles } from "@mui/styles";
const options = {
  shouldForwardProp: prop => prop !== "fontColor",
};
const customStyle = theme => ({
  root: {
    "& .MuiCardContent-root .MuiCardContent-root:last-child": {
      paddingBottom: "0 !important",
    },
    "& .MuiGrid-root .MuiGrid-container": {
      // width: "calc(100% + -30px) !important",
    },
  },
  card: {
    // minHeight: 250,
  },

  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "34px",
    [theme.breakpoints.down("md")]: {
      padding: "34px 0",
    },
  },
  title: {
    color: "#110345",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 20,
    margin: "0 !important",
    textTransform: "capitalize",
  },
  subtitle: {
    color: "#727272",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 17,
    margin: "3% 0 0 0 !important",
  },
  progressContainer: {
    padding: "0px 36px 27px 34px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0 27px 0",
    },
  },
});
const useStyles = makeStyles(customStyle);
const Img = styled("img")({
  display: "block",
  maxWidth: "90px",
  borderRadius: "10px",
});

const StyledTextField = styled(
  TextField,
  options,
)(({ fontColor }) => ({
  input: {
    color: fontColor,
    fontWeight: 600,
  },
}));

const CardTemplate = () => {
  const classes = useStyles();
  const progressBarData = {
    lightColor: "#73469970",
    darkColor: "#554886",
    value: 100,
  };

  const [client, setClient] = useState({
    action: "client",
    amount: 0,
  });
  const [finance, setFinance] = useState({
    action: "finance",
    amount: 0,
  });
  // console.log("client", client);
  // console.log("finance", finance);

  useEffect(() => {
    getPaymentTaskGoals().then(res => {
      // console.log("res", res);
      if (res.success) {
        setClient({
          ...client,
          amount: res.data.targetClients,
        });
        setFinance({
          ...finance,
          amount: res.data.financialGoal,
        });
      } else {
        // toast.error(res.message);
      }
    });
  }, []);

  const handleClientChange = e => {
    setClient({
      ...client,
      [e.target.name]: e.target.value,
    });
  };
  const handleFinanceChange = e => {
    setFinance({
      ...finance,
      [e.target.name]: e.target.value,
    });
  };

  const onBlurClientEvent = () => {
    // console.log("blured");
    paymentTaskGoals(client).then(res => {
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  const onBluFinanceEvent = () => {
    // console.log("blured");
    paymentTaskGoals(finance).then(res => {
      // console.log("res", res);
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  console.log(
    (2500).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    }),
  ); /* $2,500.00 */
  //width: "calc(100% + -44px) !important"
  return (
    <Box my={5} sx={{}} className={classes.root}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={6} md={6}>
          <Card variant="elevation" className={classes.card}>
            <CardContent>
              <div className={classes.content}>
                {/* <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}> */}
                <Box p={0} mr={2}>
                  <Img alt="profile" src="/assets/images/financeGoal.jpg" />
                </Box>
                <Box flex={1} p={1}>
                  <p className={classes.title} p={0}>
                    Meta financeira
                  </p>
                  <p className={classes.subtitle}>
                    {finance?.amount?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "brl",
                    })}{" "}
                  </p>
                </Box>
                <Box p={0} display={"flex"} flexDirection={"column"}>
                  <p className={classes.title} p={0}>
                    Insira sua meta financeira
                  </p>
                  <Button
                    variant="contained"
                    sx={{ padding: "12px 11px !important", fontWeight: "600", fontSize: 18, background: "#554886 !important" }}>
                    R$
                    <StyledTextField
                      name="amount"
                      value={finance.amount}
                      variant="standard"
                      onBlur={onBluFinanceEvent}
                      onChange={handleFinanceChange}
                      sx={{ color: "#fff !important", fontWeight: "600" }}
                      fontColor="#fff"
                    />
                  </Button>
                </Box>
              </div>
              <div className={classes.progressContainer}>
                <CustomProgressBar data={progressBarData} />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2} sm={6} md={6}>
          <Card variant="elevation" className={classes.card}>
            <CardContent>
              <div className={classes.content}>
                {/* <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}> */}
                <Box
                  p={0}
                  mr={2}
                  sx={{
                    borderRadius: "10px !important",
                  }}>
                  <Img alt="profile" src="/assets/images/clientGoal.jpg" />
                </Box>
                <Box flex={1} p={1}>
                  <p className={classes.title} p={0}>
                    Meta Clientes
                  </p>
                  <p className={classes.subtitle}>{client.amount}</p>
                </Box>
                <Box p={0} display={"flex"} flexDirection={"column"}>
                  <p className={classes.title} p={0}>
                    Insira sua meta de clientes
                  </p>
                  <Button
                    variant="contained"
                    sx={{
                      padding: "13px 16px !important",
                      minWidth: "144px",
                      fontWeight: "600",
                      fontSize: 18,
                      background: "#554886 !important",
                    }}>
                    <StyledTextField
                      name="amount"
                      value={client.amount}
                      variant="standard"
                      onBlur={onBlurClientEvent}
                      onChange={handleClientChange}
                      sx={{ color: "#fff" }}
                      fontColor="#fff"
                    />
                  </Button>
                </Box>
              </div>
              <div className={classes.progressContainer}>
                <CustomProgressBar data={progressBarData} />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardTemplate;
