import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import { getTrialPlans } from "../../helper/API/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as loginAction from "../../redux/login/action";
import { useAuth } from "../../hooks/useAuth";

const PlanCards = ({ children, loginDataResponse, loginUser, loginData, isTrial }) => {


  // console.log("isTrial", isTrial);
  const navigate = useNavigate();
  const [trialLoading, setTrialLoading] = useState(false);
  const { login, logout, loginSocialAuth, newDataSet } = useAuth();
  const handleSubmit = event => {
    setTrialLoading(true);
    getTrialPlans().then(res => {
      // console.log("res", res);
      if (res.success) {
        // loginData(loginDataResponse.data);
        console.log('loginUser : ', loginUser);
        newDataSet(loginUser);
        loginSocialAuth({ loginUser });
        setTrialLoading(false);
        navigate("/home");
      } else {
        setTrialLoading(false);
        toast.error(res.message);
      }
    });
  };

  const handleLogout = () => {
    logout();
  }

  return (
    <>
      <div id="planos" className="wp-block-group fullwidth-content is-layout-flow">
        <div className="wp-block-group__inner-container">
          <div style={{ height: "20px" }} aria-hidden="true" className="wp-block-spacer"></div>

          <p className="has-text-align-center letter-spacing-3" style={{ fontSize: "10px" }}>
            P L A N O S
          </p>

          <div className="wp-block-columns is-layout-flex wp-container-18">
            <div
              className="wp-block-column is-layout-flow"
              style={{
                flexBasis: "35%",
              }}></div>

            <div
              className="wp-block-column is-layout-flow"
              style={{
                flexBasis: "30%",
              }}>
              <h2 className="has-text-align-center styled-title2 wp-block-heading">Tem sempre um plano que cabe no seu bolso</h2>
            </div>

            <div
              className="wp-block-column is-layout-flow"
              style={{
                flexBasis: "35%",
              }}></div>
          </div>


          <div className="wp-block-columns is-layout-flex wp-container-27">
            <div
              align="center"
              className="wp-block-column is-layout-flow"
              style={{
                flexBasis: "14%",
              }}></div>
            <Grid justify="center" container sx={{ align: "center" }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {children}
            </Grid>
            <div class="wp-block-column is-layout-flow" style={{ flexBasis: "14%" }}></div>
          </div>


          {
            /*
            * Removed the trial button as per the instruction form the luis
            */
          }
          {!isTrial && (
            <div
              className="wp-block-buttons is-content-justification-center is-layout-flex"
              style={{ display: "flex", alignItems: "center" }}>
              <div className="wp-block-button wide-button-large">
                <button
                  className="wp-block-button__link has-background"
                  // href="https://app.inovajuris.com.br/novocadastro/plano=plano1"
                  style={{
                    backgroundColor: "#542d78",
                    color: "#fff",
                    border: 0,
                    marginBottom: 50
                  }}
                  disabled={trialLoading}
                  onClick={handleSubmit}
                  target="_blank"
                  rel="noreferrer noopener">
                  {
                    trialLoading ? (
                      <img src="/assets/images/loading.gif" style={{ height: 25 }} />
                    ) :
                      "Experimente grátis por 7 dias"
                  }
                </button>
              </div>
            </div>
          )}





          <div
            className="wp-block-buttons is-content-justification-center is-layout-flex"
            style={{ display: "flex", alignItems: "center" }}>
            <div className="wp-block-button wide-button-large">
              <button
                className="wp-block-button__link"
                // href="https://app.inovajuris.com.br/novocadastro/plano=plano1"
                style={{
                  backgroundColor: "gray",
                  color: "#fff",
                  border: 0,
                  marginBottom: 50
                }}
                onClick={handleLogout}
                target="_blank"
                rel="noreferrer noopener"
              >
                Sair
              </button>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

const mapStateToProp = state => {
  // console.log("state plan screen ::", state);
  return {
    loginUser: state?.loginUsers?.loginUser,
    loginDataResponse: state?.loginPlanResponseReducer?.data,
    isTrial: state?.loginUsers?.loginUser?.user?.isTrailUsed,
  };
};

const mapDispatchToProp = dispatch => {
  return {
    loginData: data => dispatch(loginAction.setLoginUser(data)),
  };
};

export default connect(mapStateToProp, mapDispatchToProp)(PlanCards);
