import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Img = styled("img")({
  maxWidth: 50,
  maxHeight: 50,
});

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DadosModal = ({ open, setOpen, handleClickOpen, handleClose }) => {
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <div>
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
          <DialogContent style={{ textAlign: "center", padding: "15px 35px" }}>
            <Img alt="icon" src="/assets/images/modalIcon.png" />
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 500, fontSize: 24, mt: 4 }}>
              Deseja cancelar seu plano?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1, fontWeight: 400, fontSize: 17, opacity: 0.5 }}>
              Cancelar sua assinatura Premium mensal.
            </Typography>
            <Button sx={{ background: "#7C51DB !important", my: 4, textTransform: "capitalize" }} variant="contained">
              Sim, desejo cancelar
            </Button>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};

export default DadosModal;
