import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CustomizedSearchBar from "../components/Inputs/SearchBar";
import ProgressTemplate from "../components/financeiro/ProgressTemplate";
import AdvanceSearchTemplate from "../components/financeiro/AdvanceSearchTemplate";
import TableTemplate from "../components/financeiro/TableTemplate";
import { Box, Card, CardContent, FormControl, Grid, InputAdornment, OutlinedInput, Paper, Tab, Tabs, Typography } from "@mui/material";
import Despesas from "./Despesas";
import { financeDashboardData, getFinanceDashboardList } from "../helper/API/paymentTaskByAdvocate";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ErrorPage from "./ErrorPage";
import Loader from "../components/Loader";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    marginBottom: "2rem",
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,

      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
    },
    "& .MuiGrid-root": {
      "& .MuiPaper-root": {
        "& .MuiCardContent-root": {
          padding: "0 0 5rem 0 !important",
        },
      },
      "& .MuiContainer-root": {
        padding: 5,
      },
    },
  },
});

const Financeiro = ({ permission }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [selectVal, setSelectVal] = useState("client");
  const [loading, setLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [chartData, setChartData] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (permission?.isOwner && permission?.financialMgt) {
      setChartLoading(true);
      const submitData = { transaction: "all" };
      financeDashboardData(submitData).then(res => {
        // console.log("res get chart ::: ", res);
        if (res.success) {
          setChartData(res.data);
          setChartLoading(false);
        } else {
          setChartLoading(false);
          toast.error(res.message);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (permission?.isOwner && permission?.financialMgt) {
      const submitData = {
        transaction: "all",
        userType: selectVal,
      };
      setLoading(true);
      getFinanceDashboardList(submitData).then(res => {
        if (res.success) {
          setTableRows(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          // toast.error(res.message);
        }
      });
    }
  }, [selectVal]);

  useEffect(() => {
    if (permission?.isOwner && permission?.financialMgt) {
      const submitData = {
        transaction: "all",
        userType: selectVal,
      };
      setLoading(true);
      getFinanceDashboardList(submitData).then(res => {
        // console.log("res", res);
        if (res.success) {
          setTableRows(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      });
    }
  }, [selectVal]);

  return (
    <>
      {permission?.isOwner && permission?.financialMgt ? (
        <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={8}>
          <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={{ xs: 0, sm: 0, md: 8 }} py={{ xs: 0, sm: 0, md: 4 }}>
            <Paper square className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="icon label tabs example">
                <Tab label="Busca" />
                <Tab label="Gráfico" />
              </Tabs>
            </Paper>
          </Box>
          {value === 0 ? (
            <>
              {chartLoading ? <Loader /> : <ProgressTemplate chartData={chartData} />}
              <AdvanceSearchTemplate setLoading={setLoading} setTableRows={setTableRows} />
              {/* <CustomizedSearchBar /> */}
              <TableTemplate
                setSelectVal={setSelectVal}
                selectVal={selectVal}
                loading={loading}
                setLoading={setLoading}
                tableRows={tableRows}
                setTableRows={setTableRows}
              />
            </>
          ) : (
            <Despesas cardData={chartData} />
          )}
        </Box>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProps)(Financeiro);
