import React from "react";
import { Box, Button, Card, CardContent, InputLabel, MenuItem, TextareaAutosize, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { FormControl, Grid, TextField } from "@mui/material";

import InputBase from "@mui/material/InputBase";

const currencies = [
  {
    value: "Honorários",
    label: "Honorários",
  },
  {
    value: "Custas/Despesas",
    label: "Custas/Despesas",
  },
  {
    value: "Outros",
    label: "Outros",
  },
];
const Reuniaos = [
  {
    value: "Dr. Júlio Rocha",
    label: "Dr. Júlio Rocha",
  },
  {
    value: "Dr. Paulo Roberto",
    label: "Dr. Paulo Roberto",
  },
  {
    value: "Dra. Renata Mello",
    label: "Dra. Renata Mello",
  },
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(4),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    // width: "100%",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      border: "none",
      // borderColor: '#80bdff',
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 1,
    // maxWidth: 500,
    "& .MuiButtonBase-root": {
      background: "#734699",
    },
    "& .MuiFormControl-root": {
      "& .MuiPaper-root": {
        border: "3px ",
      },
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

const AdicionarNota = () => {
  const [currency, setCurrency] = React.useState("skype");
  const [Reuniao, setReuniao] = React.useState("Reuniao");

  const classes = useStyles();
  const navigate = useNavigate();

  const handleChange = event => {
    setCurrency(event.target.value);
  };
  const handleReuniaoChange = event => {
    setReuniao(event.target.value);
  };

  const handleSave = () => {
    navigate("/home/adicionar-advogado");
  };

  return (
    <div>
      <Box className={classes.root} sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        <Box sx={{ display: "flex", alignItems: "center", color: "#5031A9", justifyContent: "end", marginBottom: "2rem" }}>
          <Button variant="contained" sx={{ fontWeight: 600, fontFamily: "Poppins" }} onClick={handleSave}>
            Salvar
          </Button>
        </Box>

        <TextField
          id="outlined-select-currency"
          select
          label="Buscar cliente"
          value={Reuniao}
          onChange={handleReuniaoChange}
          fullWidth
          sx={{ backgroundColor: "#fff", marginBottom: 5 }}>
          {Reuniaos.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src="/assets/images/check-circle.png" style={{ paddingRight: 5, height: 20 }} />
                {option.label}
              </div>
            </MenuItem>
          ))}
        </TextField>

        <Card
          variant="elevation"
          className={classes.card}
          sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
          <CardContent sx={{ position: "relative" }}>
            <Box sx={{ mt: 3 }}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{
                          fontWeight: 600,
                          color: "#747B96",
                          fontSize: "24px",
                        }}>
                        Valor da nota
                      </InputLabel>
                      <BootstrapInput placeholder="R$150,00" id="bootstrap-input" />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <TextareaAutosize
                      label="Mini currículo"
                      defaultValue="Descrição do lançamento"
                      aria-label="minimum height"
                      minRows={10}
                      // maxCol={8}
                      variant="outlined"
                      style={{ border: "2px solid #DBD7F4", borderRadius: "13px", width: "100%", resize: "none", paddingLeft: 10 }}
                    />
                  </Grid>

                  <Grid item md={12}>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      sx={{
                        fontWeight: 500,
                        color: "#747B96",
                        fontSize: "24px",
                        marginBottom: 1,
                      }}>
                      Tipo de nota
                    </InputLabel>

                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Buscar cliente"
                      value={Reuniao}
                      onChange={handleReuniaoChange}
                      fullWidth
                      sx={{ backgroundColor: "#fff", marginBottom: 5 }}>
                      {currencies.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <img src="/assets/images/check-circle.png" style={{ paddingRight: 5, height: 20 }} />
                            {option.label}
                          </div>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default AdicionarNota;
